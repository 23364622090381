@import  'tailwindcss/base';
@import  'tailwindcss/components';
@import  'tailwindcss/utilities';


.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-Color: rgba(0, 0, 0, 0.75);
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; 
}

.spinner {
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid #3498db; /* Couleur principale du spinner */
    border-right: 5px solid #3498db; /* Couleur principale du spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

input:focus {
    border-color: #ff9667;
    box-shadow: #ff9667; 
}

input:hover {
    border-color: #ff9667; 
}

input:focus, select:focus {
    border-color: #ff9667;
    box-shadow: #ff9667; 
}

.bg-06748c {
    background-color: #272860;
}

.bg-06748c:hover {
    background-color: #272860; 
}


.pagination {
    text-align: center;
    margin-top: 20px;
    display: inline-block;
}

.pagination-button, .pagination-nav {
    background-color: #272860;
    color: white;
    border: 1px solid transparent;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.pagination-button:hover, .pagination-nav:hover, .pagination-button.active {
    background-color: #7575c6;
    transform: translateY(-1px); /* Légère élévation au survol */
    border-color: #7575c6;
}

.pagination-button:disabled, .pagination-nav:disabled {
    background-color: #ccc;
    color: #666;
    cursor: default;
    box-shadow: none;
}

